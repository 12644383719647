import { appService, initAuth } from '@ibe/components';
import { ConfigService, defaultConfig, LoggerFactory } from '@ibe/services';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import dayjs from 'dayjs';
import fr from 'date-fns/locale/fr';
import nl from 'date-fns/locale/nl';
import localeData from 'dayjs/plugin/localeData';
import localeFr from 'dayjs/locale/fr';
import localeNl from 'dayjs/locale/nl';
import { enableKlaro, initTracking } from '@/Tracking/setup';
import '../Tracking/trackingSubscriptions';
import { ConfigUrlParams, currency, Currency, Language, languageSelection } from '@/Translations';
import isClient from '@/Util/globals';
import router from 'next/router';
import nextI18NextConfig from '../../next-i18next.config.js';
import { MGNLConfigType, Props } from '@/Util/magnolia';
import YsnConfigModel from '@/Config/YsnConfigModel';

dayjs.extend(localeData);

export const productSearchSessionKey = 'ysn-product-search-response';

export const getLocale = (lang: string) => {
  switch (lang) {
    case Language.NL:
      return localeNl;
    case Language.FR:
      return localeFr;
    default:
      return localeNl;
  }
};

const logger = LoggerFactory.get('config');
const customConfig: Partial<YsnConfigModel> = {
  defaultLanguage: 'nl',
  defaultCurrency: 'EUR',
  apiUrl: '/api/',
  configUrl: '/config.json',
  affiliateAgencyNumber: 0,
  displayFormatDate: {
    de_DE: 'DD.MM.YYYY',
    en_GB: 'DD/MM/YYYY',
    fr_FR: 'DD/MM/YYYY',
    it_IT: 'DD/MM/YYYY',
    de: 'DD.MM.YYYY',
    en: 'DD/MM/YYYY',
    nl: 'DD/MM/YYYY',
    it: 'DD/MM/YYYY',
    fr: 'DD/MM/YYYY',
    cs: 'DD/MM/YYYY'
  },
  displayFormatDateTime: {
    de_DE: 'DD.MM.YYYY HH:mm',
    en_GB: 'DD/MM/YYYY HH:mm',
    fr_FR: 'DD/MM/YYYY HH:mm',
    it_IT: 'DD/MM/YYYY HH:mm',
    de: 'DD.MM.YYYY HH:mm',
    en: 'DD/MM/YYYY HH:mm',
    nl: 'DD/MM/YYYY HH:mm',
    fr: 'DD/MM/YYYY HH:mm',
    it: 'DD/MM/YYYY HH:mm',
    cs: 'DD/MM/YYYY HH:mm'
  },
  displayFormatDateShort: {
    de_DE: 'DD.MM.',
    en_GB: 'DD/MM',
    fr_FR: 'DD/MM',
    it_IT: 'DD/MM',
    de: 'DD.MM.',
    en: 'DD/MM',
    nl: 'DD/MM',
    it: 'DD/MM',
    fr: 'DD/MM',
    cs: 'DD/MM'
  },
  displayFormatWeekDay: {
    de_DE: 'ddd DD.MM.',
    en_GB: 'ddd DD/MM',
    fr_FR: 'ddd DD/MM',
    it_IT: 'ddd DD/MM',
    de: 'ddd DD.MM.',
    en: 'ddd DD/MM',
    fr: 'ddd DD/MM',
    it: 'ddd DD/MM',
    cs: 'ddd DD/MM'
  },
  useConsentManager: true,
  googleTagManagerContainerId: 'GTM-TMBVKV6',
  datePickerMinDateOffset: 0,
  session: {
    expirationTimeNotificationOffset: 5
  },
  sessionKeyTabsShared: true,
  traveler: {
    maxChildAge: 14,
    maxInfantAge: 2,
    mockAvailable: false,
    genderValues: []
  },
  useIndexedGeoUnits: true,
  enableMultipleRooms: true,
  maxDaysInDayRangeMultiRoomSearch: 31,
  stickySearchOffsetClass: 'top-header--mobile',
  outsideElementsContainerId: 'iso',
  paxRoomConfig: {
    maxRooms: 4,
    maxCapacity: 64,
    maxAdults: 8,
    maxChildren: 8
  }
};

const durationOptions = {
  WEEKS: [1, 2, 3, 4],
  NIGHTS: [...Array.from({ length: 21 }, (_, i) => i + 1).filter(i => !!(i % 7 && i > 2))]
};

defaultConfig.packageSearchDurationOptions = durationOptions;

export const configService = new ConfigService<YsnConfigModel>(YsnConfigModel, defaultConfig);

function initLanguage(currentLanguage?: string, isServerSide?: boolean): void {
  registerLocale('fr', fr);
  registerLocale('nl', nl);
  if (!isServerSide) {
    let language = (currentLanguage || nextI18NextConfig.i18n.defaultLocale || '').split('-')[0];
    language = !!languageSelection.find(selection => language.includes(selection))
      ? language
      : Language.NL;
    appService.setLang(language);
    dayjs.locale(getLocale(language));
    setDefaultLocale(language);
    router.push(
      {
        pathname: router.pathname,
        query: router.query
      },
      router.asPath,
      { locale: appService.lang }
    );
  }
}

function initCurrency(): void {
  if (!isClient()) {
    return;
  }
  const params = new URLSearchParams(window.location.search);
  let currencyFromUrl: string | null = null;
  if (params.has(ConfigUrlParams.CURRENCY)) {
    currencyFromUrl = params.get(ConfigUrlParams.CURRENCY);
    if (currency.findIndex(cur => cur === currencyFromUrl) === -1) {
      console.warn(`Not supported currency passed via URL: ${currencyFromUrl}`);
      currencyFromUrl = null;
    }
  }
  if (appService.currency === '' && currencyFromUrl === null) {
    appService.setCurrency(Currency.EUR);
  }
}

export function initConfig(params: MGNLConfigType | undefined) {
  let externalConfig = {
    apiUrl: process.env.NEXT_PUBLIC_API_URL
  } as Partial<YsnConfigModel>;
  if (params?.['@path']) {
    externalConfig = {
      ...externalConfig,
      ...(params as Partial<YsnConfigModel>)
    };
  }
  configService.mergeIntoConfig(customConfig);
  configService.mergeIntoConfig(externalConfig as Partial<YsnConfigModel>);
}

export const boot = async (
  isDev: boolean,
  isServerSide?: boolean,
  mGNLProps?: Props,
  currentLanguage?: string
): Promise<YsnConfigModel> => {
  const { config: mGNLConfig, magnoliaContext } = mGNLProps || {};
  initConfig(mGNLConfig);
  const config = configService.get();
  logger.log('booting...');
  initLanguage(currentLanguage, isServerSide);
  if (!isServerSide) {
    initCurrency();
    await initAuth(config);
    if (!magnoliaContext?.isMagnoliaEdit && !magnoliaContext?.isMagnolia) {
      if (config.useConsentManager) {
        await enableKlaro();
      }
      await initTracking(config, isDev);
    }
  }
  await appService.hydrate(config, isServerSide || false);
  logger.log('App hydrated');
  return config;
};
