export enum Language {
  EN = 'en',
  EN_GB = 'en-GB',
  EN_US = 'en-US',
  DE = 'de',
  DE_DE = 'de-DE',
  FR = 'fr',
  IT = 'it',
  IT_it = 'it-IT',
  FR_fr = 'fr-FR',
  cs = 'cs',
  NL = 'nl'
}
export enum Currency {
  EUR = 'EUR'
}

export enum ConfigUrlParams {
  LANGUAGE = 'lng',
  CURRENCY = 'cur'
}

export const languages = Object.values(Language);
export const languageSelection = [Language.FR, Language.NL];
export const currency = Object.values(Currency);
