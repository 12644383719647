import { ConfigModel } from '@ibe/services';

class YsnConfigModel extends ConfigModel {
  hideCreateQuotationBookingButton?: boolean;
  enableMultipleRooms?: boolean;
  showDirectFlightsFilter?: boolean;
  affiliateAgencyNumber?: number;
  showAgencyCommunication?: boolean;
  maxDaysInDayRangeMultiRoomSearch?: number;
}

export default YsnConfigModel;
