import React, { FunctionComponent, PropsWithChildren } from 'react';
import { observer } from 'mobx-react';

const BlankPageLayout: FunctionComponent<PropsWithChildren<{}>> = observer(
  (props: PropsWithChildren<{}>) => {
    return (
      <div className="page__blank">
        <div className="page__center">{props.children}</div>
      </div>
    );
  }
);

export default BlankPageLayout;
