import {
  ApiAddItemRequest,
  ApiBaseData,
  ApiBookedItem,
  ApiBooking,
  ApiConfigurationDataRequestFromJSON,
  ApiConfigurationDataType,
  ApiPackageCart,
  ApiTraveler,
  ApiUpdateServiceNotesRequest
} from '@ibe/api';
import { ApiService, BookingService, LoggerFactory, SessionStorage, clone } from '@ibe/services';
import { ImageProgressbarStep } from '@ibe/components';
import dayjs from 'dayjs';
import { action, computed, makeObservable, observable, runInAction, toJS } from 'mobx';
import { faBed, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import Keys from '@/Translations/generated/en/Checkout.json.keys';
import StepId from './StepId';
// eslint-disable-next-line import/no-cycle
import WorkflowService from '../../Services/WorkflowService';

const logger = LoggerFactory.get('CheckoutStore');
class CheckoutStore {
  static PACKAGE_CART_ID = 'best-price-package-cart-id';
  stepActive: StepId | null = null;

  isTravellersDataValid = false;

  hasExtras = true;

  travelerDataErrors: Map<number, { [p: string]: string[] }> = new Map();

  titles: ApiBaseData[] | undefined = undefined;

  countries: ApiBaseData[] | undefined = undefined;

  error: string | null | undefined = undefined;

  _isBusy = false;

  _isLoading = false;

  _packageCart: ApiPackageCart | null = null;

  _booking: ApiBooking | null = null;

  _packageCartIdStorage: SessionStorage<{ packageCartId: string }> = new SessionStorage<{
    packageCartId: string;
  }>(CheckoutStore.PACKAGE_CART_ID);

  constructor(private api: ApiService, private ibeUrl: string) {
    makeObservable(this, {
      stepActive: observable,
      isTravellersDataValid: observable,
      hasExtras: observable,
      travelerDataErrors: observable,
      titles: observable,
      countries: observable,
      error: observable,

      _isLoading: observable,
      _isBusy: observable,

      _booking: observable,
      _packageCart: observable,
      _packageCartIdStorage: observable,

      steps: computed,
      isBusy: computed,
      isLoading: computed,

      loadConfigurationData: action,
      loadCart: action,
      attemptBooking: action,
      updateTravelers: action,
      packageCartAddItem: action,
      packageCartSelectItems: action,
      packageCartRemoveItem: action,
      loadCountries: action,
      setActiveStep: action,
      setIsTravellersDataValid: action,
      setPackageCart: action,
      setPackageCartId: action,
      setIsLoading: action,
      setHasExtras: action
    });
  }

  async loadCountries(): Promise<void> {
    let response: ApiBaseData[] = [];
    let err: string | null = null;
    try {
      await this.api.getAllCountries().then(countries => {
        response = countries;
      });
    } catch (e) {
      logger.warn(e);
      err = 'Could not load countries data';
    }
    runInAction(() => {
      this.error = err;
      this.countries = response;
    });
  }

  async loadCart(): Promise<void> {
    let responseCart: ApiPackageCart | null = null;
    let responseBooking: ApiBooking | null = null;
    let error: string | null = null;
    this.setIsLoading(true);
    try {
      responseCart = await this.api.load(this.packageCartId || '');
    } catch (err) {
      logger.error(err);
      error = 'Could not load package cart';
    }
    try {
      if (!!responseCart && !!responseCart?.bookingId) {
        responseBooking = await this.api.getBooking(responseCart.bookingId);
        logger.log('getBooking', toJS(responseBooking));
      } else {
        logger.error('Could not load booking');
        error = 'Could not load booking';
      }
    } catch (err) {
      logger.error(err);
      error = 'Could not load booking cart';
    }
    this.setIsLoading(false);
    runInAction(() => {
      if (!!responseCart && !!responseBooking) {
        this.setPackageCart(responseCart);
        this.setPackageCartId(responseCart.id);
        this.setBooking(responseBooking);
      }
      this.error = error;
    });
  }

  async attemptBooking() {
    let responseCart: ApiPackageCart | null = null;
    let responseBooking: ApiBooking | null = null;
    let error: string | null = null;
    this.setIsLoading(true);
    try {
      responseCart = await this.api.attemptBooking(this.packageCartId || '');
    } catch (err) {
      logger.error(err);
      error = 'Booking attempt from package cart failed';
    }
    try {
      if (!!responseCart && !!responseCart?.bookingId) {
        responseBooking = await this.api.getBooking(responseCart.bookingId);
        logger.log('getBooking', toJS(responseBooking));
      } else {
        logger.error('Could not load booking');
        error = 'Could not load booking';
      }
    } catch (err) {
      logger.error(err);
      error = 'Could not load booking cart';
    }
    this.setIsLoading(false);
    runInAction(() => {
      if (!!responseCart && !!responseBooking) {
        this.setPackageCart(responseCart);
        this.setPackageCartId(responseCart.id);
        this.setBooking(responseBooking);
      }
      this.error = error;
    });
  }

  async updateTravelers(travelers: ApiTraveler[]): Promise<void> {
    let responseBooking: ApiBooking | null = null;
    let error: string | null = null;

    if (!this._booking) {
      return Promise.reject();
    }

    this.setIsLoading(true);
    try {
      responseBooking = await this.api.updateTravelers(this._booking.id, false, travelers);
    } catch (err) {
      logger.error(err);
      error = 'Update travelers failed';
    }

    this.setIsLoading(false);
    runInAction(() => {
      if (!!responseBooking) {
        this.setBooking(responseBooking);
      }
      this.error = error;
    });
  }

  async addVoucherCodesToBooking(voucherCodes: string[]): Promise<void> {
    let responseBooking: ApiBooking | null = null;
    let error: string | null = null;

    if (!this._booking) {
      return Promise.reject();
    }

    this.setIsLoading(true);
    try {
      responseBooking = await this.api.addVoucherCodes(this._booking.id, voucherCodes);
    } catch (err) {
      logger.error(err);
      error = 'Add voucher codes failed';
    }

    this.setIsLoading(false);
    runInAction(() => {
      if (!!responseBooking) {
        this.setBooking(responseBooking);
      }
      this.error = error;
    });
  }

  async addPromoCodesToBooking(promoCodes: string[]): Promise<void> {
    let responseBooking: ApiBooking | null = null;
    let error: string | null = null;

    if (!this._booking) {
      return Promise.reject();
    }

    this.setIsLoading(true);
    try {
      responseBooking = await this.api.addPromoCodes(this._booking.id, promoCodes);
    } catch (err) {
      logger.error(err);
      error = 'Add promo codes failed';
    }

    this.setIsLoading(false);
    runInAction(() => {
      if (!!responseBooking) {
        this.setBooking(responseBooking);
      }
      this.error = error;
    });
  }

  async packageCartAddItem(componentId: string, itemId: string): Promise<void> {
    let errorMessage: string | null = null;
    let res: ApiPackageCart | null = null;
    this.setIsLoading(true);
    try {
      res = await this.api.addItem(this.packageCartId || '', componentId, itemId);
    } catch (error) {
      logger.warn(error);
      errorMessage = 'API client was not able to add item to package cart';
    }
    this.setIsLoading(false);

    runInAction(() => {
      this._packageCart = res;
      this.error = errorMessage;
    });
  }

  async packageCartSelectItems(
    componentId: string,
    selectedItemsIds: string[],
    bookingOptions?: { [key: string]: ApiAddItemRequest }
  ): Promise<void> {
    let res: ApiPackageCart | null = null;
    let errorMessage: string | null = null;
    this.setIsLoading(true);
    try {
      res = await this.api.selectItems(this.packageCartId || '', componentId, {
        bookingOptions: bookingOptions || {},
        selectedItemIds: selectedItemsIds
      });
    } catch (error) {
      logger.warn(error);
      errorMessage = 'API client was not able to select items in package cart';
    }

    this.setIsLoading(false);
    runInAction(() => {
      this._packageCart = res;
      this.error = errorMessage;
      logger.log('Finished select items in package cart');
    });
  }

  async packageCartRemoveItem(componentId: string, removedItemId: string): Promise<void> {
    let res: ApiPackageCart | null = null;
    let errorMessage: string | null = null;
    this.setIsLoading(true);
    try {
      res = await this.api.removeItem(this.packageCartId || '', componentId, removedItemId);
    } catch (error) {
      logger.warn(error);
      errorMessage = 'API client was not able to remove items in package cart';
    }
    this.setIsLoading(false);

    runInAction(() => {
      this._packageCart = res;
      this.error = errorMessage;
      logger.log('Finished remove items in package cart');
    });
  }

  async loadConfigurationData(): Promise<void> {
    let response: { [key: string]: ApiBaseData[] } = {};
    let err: string | null = null;
    try {
      const request = ApiConfigurationDataRequestFromJSON({
        typeCodes: [ApiConfigurationDataType.SALUTATIONS]
      });
      const res = await this.api.getConfiguration(request);

      response = res.data;
    } catch (e) {
      logger.warn(e);
      err = 'Could not load configuration data';
    }
    runInAction(() => {
      this.error = err;
      this.titles = response[ApiConfigurationDataType.SALUTATIONS];
    });
  }

  setIsTravellersDataValid(value: boolean): void {
    this.isTravellersDataValid = value;
  }

  setHasExtras(value: boolean): void {
    this.hasExtras = value;
  }

  get steps(): ImageProgressbarStep[] {
    return [
      {
        description: Keys.TravelDates,
        selected: false,
        image: faBed
      },
      {
        description: Keys.Extras,
        selected: this.stepActive === StepId.EXTRAS,
        image: faBed,
        optional: true,
        skipped: !this.hasExtras
      },
      {
        description: Keys.CustomerDetails,
        selected: this.stepActive === StepId.TRAVELER_DETAILS,
        image: faUserFriends
      },

      {
        description: Keys.Confirmation,
        selected: this.stepActive === StepId.CONFIRMATION,
        image: faCalendarCheck
      }
    ];
  }

  handleNavigationClick(ws: WorkflowService, clickedIndex: number): string {
    const currentIndex = ws.currentStepIndex;

    if (clickedIndex <= currentIndex) {
      const targetStep = ws.steps[clickedIndex];
      if (clickedIndex < 0) {
        return this.ibeUrl;
      }
      return targetStep.slug || '';
    }
    return ws.steps[currentIndex].slug || '';
  }

  getEarliestStartDate = (bookedItems: ApiBookedItem[] | undefined): string | null => {
    let date: string | null = null;
    if (bookedItems && bookedItems.length > 0) {
      bookedItems.forEach(item => {
        if (!date) {
          date = dayjs(item.startDate).format('YYYY-MM-DD');
        } else if (!dayjs(date).isBefore(item.startDate)) {
          date = dayjs(item.startDate).format('YYYY-MM-DD');
        }
      });
    }
    return date;
  };

  setActiveStep(value: StepId | null): void {
    this.stepActive = value;
  }

  setBooking = (booking: ApiBooking): void => {
    this._booking = booking;
  };

  get booking(): ApiBooking | null {
    return this._booking ? this._booking : null;
  }

  setPackageCart = (cart: ApiPackageCart): void => {
    this._packageCart = cart;
  };

  get packageCart(): ApiPackageCart | null {
    return this._packageCart;
  }

  setPackageCartId(packageCartId: string): void {
    this._packageCartIdStorage.set({ packageCartId });
  }

  get packageCartId(): string | undefined {
    return this._packageCartIdStorage.get()?.packageCartId;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  setIsLoading(b: boolean): void {
    this._isLoading = b;
  }

  static getTravelers(
    travellers: ApiTraveler[],
    travelerAssignment: { [key: string]: string[] }
  ): Record<string, ApiTraveler[]> {
    const travelers: Record<string, ApiTraveler[]> = {};

    Object.entries(travelerAssignment).forEach(([key, personIds]) => {
      travelers[key] = [];
      personIds.forEach(personId => {
        const traveler = BookingService.getTraveler(travellers, personId);
        if (traveler) {
          travelers[key]?.push(traveler);
        }
      });
    });
    return travelers;
  }

  setIsBusy = (a: boolean) => {
    this._isBusy = a;
  };

  get isBusy(): boolean {
    return this._isBusy;
  }

  async updateServiceNotes(
    rq: ApiUpdateServiceNotesRequest,
    attempt: boolean
  ): Promise<ApiBooking> {
    try {
      if (rq) {
        const response = await this.api.updateServiceNotes(attempt, rq);
        this._booking = await response;
        return await response;
      }
    } catch (e) {
      logger.error('Update service notes failed');
    }
    return Promise.reject();
  }
}

export default CheckoutStore;
