import React, { ComponentType, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import createCache from '@emotion/cache';
import { ThemeProvider, CacheProvider } from '@emotion/react';
import getTheme from '../Theme/theme';
import App, { AppContext, AppProps } from 'next/app';
import { enableStaticRendering } from 'mobx-react';
import '../Theme/Styles/Bootstrap.scss';
import '../Theme/Styles/IBE.scss';
import { boot, productSearchSessionKey } from '@/Config/config';
import { LoggerFactory, routeChangeEvent } from '@ibe/services';
import {
  GlobalTranslationFunction,
  GlobalTranslationContextProvider,
  IsNextEnvContext,
  IsOnProductPageProvider,
  useAuth,
  SessionTimeOutModal,
  useConfig,
  useAppService
} from '@ibe/components';
import { appWithTranslation, SSRConfig } from 'next-i18next';
import isClient, { stepSessionStorage } from '../Util/globals';
import nextI18NextConfig from '../../next-i18next.config.js';
import useTranslation, { nameSpaces } from '@/Util/useTranslation';
import GlobalWindowProvider from '../Util/GlobalWindowProvider';
import { ClientSideBootingFinishedContext } from '@/Util/useClientSideBootingFinished';
import ErrorBoundary from '@/components/ErrorBoundary';
import { NextPage } from 'next';
import { SearchPropertiesProvider } from '@/Util/useSearchProperties';
import { GlobalMGLPropsContext } from '@/Util/GlobalMGLPropsContext';
import { Props } from '@/Util/magnolia';
import { IsBookingInitializedProvider } from '@/Util/useIsBookingInitialized';
import { useRouter } from 'next/router';
import initTrackingSubscriptions from '@/Tracking/trackingSubscriptions';
import CheckoutStore from '@/components/checkout/CheckoutStore';
import { useMount } from 'react-use';
import { ApiAffiliateRequestFromJSON } from '@ibe/api';
import ApplyBranding from '@/Util/ApplyBranding';

const logger = LoggerFactory.get('App');

const cache = createCache({ key: 'next' });

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type ApplicationProps = Pick<AppProps, 'Component' | 'pageProps'> & {
  props: { isProduction: boolean };
  Component: NextPageWithLayout;
};

const Application = ({ Component, pageProps, props }: ApplicationProps) => {
  enableStaticRendering(!isClient());
  const { isProduction } = props;
  const serverSideBootingFinished = useRef<boolean>(false);
  const [clientSideBootingFinished, setClientSideBootingFinished] = useState<boolean>(false);
  const auth = useAuth();
  const appService = useAppService();
  const getLayout = Component.getLayout ?? (page => page);
  const router = useRouter();
  const config = useConfig();
  const [sessionTimeOutNotificationOffset] = useState(
    config.session.expirationTimeNotificationOffset
  );
  const [sessionExpirationTimeOut, setSessionExpirationTimeOut] = useState(
    auth.authService.getRemainingTokenTime()
  );
  const [cssVars, setCssVars] = useState<{ [key: string]: string } | undefined>(undefined);
  const [faviconUrl, setFaviconUrl] = useState<string | undefined>(undefined);
  if (!serverSideBootingFinished.current && !isClient()) {
    (async () => {
      await boot(!isProduction, true, pageProps, undefined);
      serverSideBootingFinished.current = true;
    })();
  }

  const { i18n } = useTranslation('Header');

  useMount(() => {
    if (!clientSideBootingFinished && isClient()) {
      (async () => {
        const { magnoliaContext } = (pageProps || {}) as Props;

        const config = await boot(!isProduction, false, pageProps, i18n.language);
        if (
          !magnoliaContext?.isMagnoliaEdit &&
          !magnoliaContext?.isMagnolia &&
          pageProps?.config?.useConsentManager
        ) {
          initTrackingSubscriptions(config);
          logger.log('tracking initialized...');
        }
        if (config.affiliateAgencyNumber !== undefined) {
          const affResponse = await appService.api.getAffiliateData(
            ApiAffiliateRequestFromJSON({
              number: config.affiliateAgencyNumber
            })
          );
          setCssVars(affResponse.cssVars);
          if (affResponse.metaContent?.content?.favicon) {
            setFaviconUrl(`${affResponse.metaContent?.content?.favicon}`);
          }
        }
        setClientSideBootingFinished(true);
      })();
    }
  });

  useEffect(() => {
    setSessionExpirationTimeOut(auth.authService.getRemainingTokenTime());
  }, [auth.authService, auth.authState.token, router.pathname]);

  useEffect(() => {
    const onKeyup = (e: KeyboardEvent): void => {
      if (e.ctrlKey && e.shiftKey && e.altKey && e.code === 'KeyN' && !isProduction) {
        console.log(nameSpaces);
      }
    };

    document.addEventListener('keyup', onKeyup);

    return () => {
      document.removeEventListener('keyup', onKeyup);
    };
  }, []);

  useEffect(() => {
    routeChangeEvent.broadcast({
      pathname: document.location.pathname,
      search: document.location.search
    });
  }, [JSON.stringify(router?.query)]);

  const logout = () => {
    auth.removeToken();
    auth.authService.invalidate();
    sessionStorage.removeItem(config.sessionKeyCart);
    sessionStorage.removeItem(config.sessionKeySearchParams);
    sessionStorage.removeItem(productSearchSessionKey);
    sessionStorage.removeItem(CheckoutStore.PACKAGE_CART_ID);
    stepSessionStorage.clear();
    router.push('/');
  };

  return serverSideBootingFinished.current || clientSideBootingFinished ? (
    <div id={'iso'}>
      {!!cssVars && <ApplyBranding cssVars={cssVars} faviconUrl={faviconUrl} />}
      <CacheProvider value={cache}>
        <GlobalWindowProvider>
          <GlobalTranslationContextProvider
            globalTranslation={(useTranslation as unknown) as GlobalTranslationFunction}
          >
            <IsNextEnvContext.Provider value={true}>
              <GlobalMGLPropsContext.Provider value={pageProps}>
                <ClientSideBootingFinishedContext.Provider value={clientSideBootingFinished}>
                  <IsOnProductPageProvider>
                    <IsBookingInitializedProvider>
                      <SearchPropertiesProvider>
                        <ThemeProvider theme={getTheme()}>
                          <ErrorBoundary>
                            {getLayout(<Component key={router.locale} {...pageProps} />)}
                            {auth.authState.token && sessionExpirationTimeOut !== null && (
                              <SessionTimeOutModal
                                sessionTimeOut={sessionExpirationTimeOut}
                                sessionTimeOutNotificationOffset={
                                  sessionTimeOutNotificationOffset * 60 * 1000
                                }
                                onLogoutRedirection={logout}
                                silentRefreshThrottle={10_000}
                              />
                            )}
                          </ErrorBoundary>
                        </ThemeProvider>
                      </SearchPropertiesProvider>
                    </IsBookingInitializedProvider>
                  </IsOnProductPageProvider>
                </ClientSideBootingFinishedContext.Provider>
              </GlobalMGLPropsContext.Provider>
            </IsNextEnvContext.Provider>
          </GlobalTranslationContextProvider>
        </GlobalWindowProvider>
      </CacheProvider>
    </div>
  ) : (
    <></>
  );
};

Application.getInitialProps = async (appContext: AppContext) => {
  const ctx = await App.getInitialProps(appContext);
  return {
    ...ctx,
    props: { isProduction: process.env.NODE_ENV === 'production' }
  };
};

export default appWithTranslation(
  (Application as unknown) as ComponentType<AppProps & { pageProps: SSRConfig }>,
  nextI18NextConfig
);
