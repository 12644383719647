import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';

import packageJson from '../../package.json';
import { Language, languageSelection } from '@/Translations';
import { i18n } from 'next-i18next';
import isClient from '@/Util/globals';
import YsnConfigModel from '@/Config/YsnConfigModel';

const getLanguageKlaro = () => {
  if (!isClient()) return Language.NL.toLocaleLowerCase();
  const formattedPathname = `${window.location.pathname}/`;
  const splittedPath = formattedPathname.split('/');
  const foundLanguage = Object.values(Language).find(lng => {
    if (splittedPath.includes(lng)) return lng;
  });
  return foundLanguage || Language.NL;
};

const defaultTranslations = {
  en: {
    consentNotice: {
      description:
        'Hi! Could we please enable some additional services for Analytics? You can always change or withdraw your consent later.',
      choose: 'Let me choose',
      decline: 'I decline',
      ok: "That's ok"
    },
    consentModal: {
      title: '<h3>Consent Settings</h3>',
      description:
        'Hi! We use  cookies on our website. Some are essential, others help us to improve your user experience. Please choose how much data you would like to share. You can always change or withdraw your consent later. Here you can see and customize the information that we collect about you.'
    },
    googleAnalytics: {
      title: 'Google Analytics',
      description: 'Collecting anonymous usage data to improve the content served on this website.'
    },
    necessary: {
      title: 'Strictly necessary',
      description:
        'These trackers are used for activities that are strictly necessary to operate or deliver the service you requested from us and, therefore, do not require you to consent.'
    },
    purposes: {
      analytics: 'Analytics',
      functional: 'Functional'
    },
    button: {
      decline: 'I decline',
      acceptSelected: 'Accept selected',
      acceptAll: 'Accept all'
    }
  }
};

const loadTranslationsConfig = () => {
  const translations: any = {};

  if (i18n && i18n.isInitialized) {
    for (const lng of languageSelection) {
      const translationsObject = i18n.getResourceBundle(lng, 'klaro');
      if (translationsObject) {
        translations[lng] = translationsObject;
      }
    }
  }
  return translations;
};

export const getKlaroConfig = (lang?: string) => {
  const loadedTranslations = loadTranslationsConfig();
  const langUsed = lang || getLanguageKlaro();

  return {
    styling: {
      theme: ['light']
    },
    acceptAll: true,
    htmlTexts: true,
    noticeAsModal: true,
    lang: langUsed,
    translations:
      loadedTranslations[langUsed] !== undefined ? loadedTranslations : defaultTranslations,
    services: [
      { name: 'necessary', purposes: ['functional'], default: true, required: true },
      {
        name: 'googleAnalytics',
        purposes: ['analytics'],
        default: true,
        onAccept: function () {
          tracking?.plugins?.enable('google-tag-manager');
        },
        onDecline: function () {
          tracking?.plugins?.disable('google-tag-manager', () => null);
        }
      }
    ]
  };
};

const analyticsOptions = {
  app: packageJson.name,
  version: packageJson.version,
  debug: false,
  plugins: []
};

export let tracking = Analytics(analyticsOptions);

export const enableKlaro = async (): Promise<void> => {
  const Klaro = await import('klaro/dist/klaro-no-css');
  window.klaro = Klaro;
  window.klaroConfig = getKlaroConfig();
  Klaro.setup(getKlaroConfig());
};

export const initTracking = async (config: YsnConfigModel, isDev: boolean) => {
  let consent = true;
  if (config.useConsentManager) {
    const manager = window.klaro.getManager();
    consent = manager.getConsent('googleAnalytics') && manager.confirmed;
  }

  if (config.googleTagManagerContainerId) {
    analyticsOptions.debug = isDev;
    if (
      !analyticsOptions.plugins.some((item: { name: string }) => item.name === 'google-tag-manager')
    ) {
      analyticsOptions.plugins.push(
        googleTagManager({
          containerId: config.googleTagManagerContainerId,
          enabled: consent
        }) as never
      );
    }
  }

  tracking = Analytics(analyticsOptions);
};
