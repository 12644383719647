import {
  bookingInitEvent,
  checkoutFinishedEvent,
  checkoutStartedEvent,
  itemListEvent,
  routeChangeEvent,
  TrackingEvent
} from '@ibe/services';
import { tracking } from './setup';
import isClient from '@/Util/globals';
import YsnConfigModel from '@/Config/YsnConfigModel';

const checkConsent = (): boolean => {
  if (isClient()) {
    const manager = window.klaro.getManager();
    manager.loadConsents();
    return manager.getConsent('googleAnalytics') && manager.confirmed;
  }
  return false;
};

const initTrackingSubscriptions = (config: YsnConfigModel): void => {
  routeChangeEvent.subscribe(() => {
    if (checkConsent() && !!config.googleTagManagerContainerId) {
      tracking?.plugins?.enable('google-tag-manager').then(() => tracking.page());
    }
  });

  bookingInitEvent.subscribe(payload => {
    if (checkConsent() && !!config.googleTagManagerContainerId) {
      tracking?.plugins
        ?.enable('google-tag-manager')
        .then(() => tracking.track(TrackingEvent.CART_INIT, payload));
    }
  });
  checkoutStartedEvent.subscribe(payload => {
    if (checkConsent() && !!config.googleTagManagerContainerId) {
      tracking?.plugins
        ?.enable('google-tag-manager')
        .then(() => tracking.track(TrackingEvent.CHECKOUT_START, payload));
    }
  });
  checkoutFinishedEvent.subscribe(payload => {
    if (checkConsent() && !!config.googleTagManagerContainerId) {
      tracking?.plugins
        ?.enable('google-tag-manager')
        .then(() => tracking.track(TrackingEvent.CHECKOUT_FINISH, payload));
    }
  });

  itemListEvent.subscribe(payload => {
    if (checkConsent() && !!config.googleTagManagerContainerId) {
      tracking?.plugins
        ?.enable('google-tag-manager')
        .then(() => tracking.track(TrackingEvent.PRODUCT_LIST_VIEW, payload));
    }
  });
};

export default initTrackingSubscriptions;
