const HttpBackend = require('i18next-http-backend');
const ChainedBackend = require('i18next-chained-backend');
const resourcesToBackend = require('i18next-resources-to-backend/cjs');
const LanguageDetector = require('i18next-browser-languagedetector');

const Resources = resourcesToBackend((lng, nms) =>
  require(`./public/translations/generated/${lng}/${nms}.json`)
);

module.exports = {
  i18n: {
    defaultLocale: 'nl',
    locales: ['nl', 'fr']
  },
  preload: ['nl'],
  allowMultiLoading: true,
  defaultNS: 'Header',
  fallbackLng: 'nl',
  initImmediate: false,
  debug: false,
  use: [ChainedBackend, LanguageDetector],
  interpolation: { escapeValue: true },
  backend: {
    backends: [HttpBackend, Resources],
    backendOptions: [
      {
        loadPath: `${process.env.MAGNOLIA_HOST}/.rest/translations/{{ns}}.{{lng}}.json`,
        reloadInterval: 60 * 2 * 1000 //60 * 60 * 1000, // ms
      },
      {}
    ]
  },
  ns: [
    'flight-ssr-modal',
    'Header',
    'Footer',
    'ibe',
    'flight',
    'FlightPage',
    'flight-search',
    'geo-unit-select-async',
    'date-range-input',
    'filter-option',
    'date-range-single-field',
    'geo-unit-checkbox-async',
    'traveller-class-selection',
    'horizontal-list',
    'service-list',
    'package',
    'added-to-cart-modal',
    'Checkout',
    'Checkout-B2B',
    'seatmap',
    'summary',
    'booked-item-component',
    'flight-summary',
    'iso-items-base',
    'notes-formatter',
    'traveller-builder',
    'checkout-address-data-form',
    'agency-reference',
    'custom-pagination',
    'payment-option',
    'traveller-display',
    'klaro',
    'login',
    'login-form',
    'customer-display',
    'booking-list',
    'bestPricePackageListItem',
    'service-search',
    'PackageSearch',
    'package-details',
    'pax-room-selection',
    'filter-range',
    'search-content',
    'booking-search',
    'booking-details',
    'booking-documents',
    'ssrs',
    'booking-summary-bar',
    'duration',
    'booking-details-module',
    'booking-summary',
    'password-change',
    'CommunicationModal',
    'newsletter',
    'password-change-form',
    'session-time-out-modal',
    'mobile-modal-input-wrapper',
    'flight-segment-details-popup',
    'destinations',
    'BestPriceListSelectionsSearch',
    'bestPricePackageListItemFlightInternal'
  ],
  serializeConfig: false
};
