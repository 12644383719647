import React, { Component, ErrorInfo, ReactNode } from 'react';
import { LoggerFactory } from '@ibe/services';
import BlankPageLayout from '@/Layouts/BlankPageLayout';
import { withRouter } from 'next/router';
import { WithRouterProps } from 'next/dist/client/with-router';
import { Button } from 'reactstrap';

const logger = LoggerFactory.get('ErrorBoundary');

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

type ErrorProps = WithRouterProps & Props;

class ErrorBoundary extends Component<ErrorProps, State> {
  constructor(props: ErrorProps) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logger.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <BlankPageLayout>
          <div className="mt-3">
            <h1>Runtime Error. Please try again</h1>
            <Button
              color="primary"
              className="mt-3"
              onClick={(): void => this.props.router.reload()}
            />
          </div>
        </BlankPageLayout>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
