import { useTranslation as nextUseTranslation } from 'next-i18next';
import { UseTranslationResponse } from 'react-i18next';

export const nameSpaces: string[] = [];

function useTranslation(
  namespace: string,
  useSuspense?: boolean
): UseTranslationResponse<string, string> {
  if (!nameSpaces.includes(namespace)) {
    nameSpaces.push(namespace);
  }

  return nextUseTranslation(namespace, { useSuspense: useSuspense ?? true });
}

export default useTranslation;
